import './indexPage.less'
import Menu from '../components/Menu';
import React, { useState, useEffect } from 'react';
import { Button, Layout, Popover, Avatar } from 'antd';
import {
  UserOutlined,
  ExportOutlined
} from '@ant-design/icons';
import { Outlet, useSearchParams } from 'react-router-dom';
import { dataUrl } from '../service/config';
const { Header, Sider, Content } = Layout;

function IndexPage(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [portrai, setPortrait] = useState('')
  useEffect(() => {
    console.log(!localStorage.getItem('accessToken'))
    if (!localStorage.getItem('accessToken')) {
      // 判断登录状态
      props.navigate('/')
      return;
    }
    setPortrait(localStorage.getItem('portrait'))
    console.log(searchParams.get('name'))
  }, [])
  const outLogin = () => {
    localStorage.clear()
    sessionStorage.clear()
    props.navigate('/login')
  }
  const content = (
    <div className='popo_content'>
      <div className='popo_item'><UserOutlined /> 我的资料</div>
      <div className='popo_item' onClick={outLogin}><ExportOutlined /> 退出登录</div>
    </div>
  );
  return (
    <Layout>
      <Header className='index_header'>
        <div className="logo">
          <img className='logo_img' src={require('./../assets/imgs/logo.png')} alt='' />系统管理
        </div>
        <div className='header_context'>
          <Popover placement="bottomRight" content={content}>
            <Avatar size="large" icon={portrai ? <img className='header_img' src={dataUrl + portrai} /> : <UserOutlined />} />
          </Popover>
        </div>
      </Header>
      <Layout>
        <Sider trigger={null} collapsible>
          <div className='menu_box'>
            <Menu />
          </div>
        </Sider>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
};
export default IndexPage