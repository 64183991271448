import { Button, Input, message, Table } from "antd";
import { useEffect, useState } from "react";
import {
  API_getSignUpList,
  API_getSignUpPhone,
  API_setSignUpPhone,
} from "../../service/common";

const SignUp = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSIze] = useState(10);
  const [applyList, setApplyList] = useState([]);
  const [phone, setPhone] = useState("");
  const [total, setTotal] = useState(0);
  const columns = [
    { title: "姓名", key: "corporateName", dataIndex: "corporateName" },
    {
      title: "联系方式",
      key: "corporateContact",
      dataIndex: "corporateContact",
    },
    { title: "备注", key: "remark", dataIndex: "remark" },
    { title: "申请时间", key: "createTime", dataIndex: "createTime" },
  ];

  const onChange = (e) => {
    setPageIndex(e);
  };
  useEffect((e) => {
    API_getSignUpPhone().then((res) => {
      setPhone(res.data);
    });
  }, []);
  useEffect(
    (e) => {
      API_getSignUpList({ pageindex: pageIndex, pageSize: 20 }).then((res) => {
        setApplyList(res.data);
      });
    },
    [pageIndex]
  );

  const setPhoneNumber = () => {
    API_setSignUpPhone({ phone: phone }).then((res) => {
      message.success("号码更新成功");
    });
  };
  return (
    <div>
      <div className="flex-start table-top">
        通知电话:<Input type='number' onChange={e => { setPhone(e.target.value);}} style={{width:"200px",marginLeft:"10px"}} value={phone}></Input>
        <Button type='primary'  style={{marginLeft:"10px"}} onClick={setPhoneNumber}>更新</Button>
      </div>
      <Table
        columns={columns}
        pagination={{
          pageSize: pageSize,
          total: total,
          onChange: onChange,
          pageSizeOptions: [],
        }}
        dataSource={applyList}
      />
    </div>
  );
};

export default SignUp;
