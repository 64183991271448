//http.ts
import axios from 'axios'
import {message } from 'antd';
import { useNavigate } from 'react-router-dom';
// 设置请求头和请求路径
axios.defaults.baseURL = process.env.REACT_APP_API
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
        //@ts-ignore
        config.headers.Authorization ='Bearer '+token
    }
    return config
},
    (error) => {
        return error
    }
)
// 响应拦截
axios.interceptors.response.use((res) => {
    if (res.data.status !='OK') {
      message.warning(res.data.message);
      return Promise.reject(res)
    } 
    return res;
  },(err)=>{
    if(err.response.status===401){
     window.location.href='#/login'
      return Promise.reject(err)
    }else{
        message.warning(err.message);
    }
   
  });

const http = {
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(url, { params })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.data)
                })
        })
    },
    delete(url, params) {
        return new Promise((resolve, reject) => {
            axios
                .delete(url, { params })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.data)
                })
        })
    },
    post(url, params) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, JSON.stringify(params))
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.data)
                })
        })
    },
    put(url, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(url, JSON.stringify(params),{
            headers: { "Content-Type": "application/json;charset=UTF-8" },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.data);
          });
      });
    },
    upload(url, file) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, file, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.data)
                })
        })
    },
    download(url) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = url
        iframe.onload = function () {
            document.body.removeChild(iframe)
        }
        document.body.appendChild(iframe)
    },
}
export default http

