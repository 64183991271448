import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import {
  API_addUser,
  API_editUser,
  API_editUserStatus,
  API_getRoleList,
  API_getUserList,
} from "../../service/common";
import { EditOutlined } from "@ant-design/icons";
const UserManagement = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSIze] = useState(10);
  const [applyList, setApplyList] = useState([]);
  const [phone, setPhone] = useState("");
  const [total, setTotal] = useState(0);
 const [roleList,setRoleList]=useState([])
  const columns = [
    { title: "姓名", align: "center", key: "name", dataIndex: "name" },
    {
      title: "手机号",
      key: "phone",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "用户类型",
      key: "userType",
      dataIndex: "userType",
      align: "center",
      render: function (userType, context) {
        if (userType == 1) {
          return "个人";
        } else if (userType == 2) {
          return "企业";
        } else if (userType == 4) {
          return "机构";
        }else if (userType == 3) {
          return "员工";
        }else if (userType == 5) {
          return "客户";
        }
      },
    },
    {
      title: "用户权限",
      key: "roleName",
      dataIndex: "roleName",
      align: "center",
    },
    {
      title: "注册时间",
      key: "registTime",
      align: "center",
      dataIndex: "registTime",
    },
    {
      title: "账号状态",
      key: "enable",
      align: "center",
      dataIndex: "enable",
      render: function (enable) {
        let status;
        if (enable) {
          status = <Tag color="green">正常</Tag>;
        } else {
          status = <Tag color="red">停用</Tag>;
        }
        return status;
      },
    },
    {
      title: "操作",
      key: "enable",
      dataIndex: "enable",
      align: "center",
      render: function (enable, itemData) {
        return (
          <div>
            <Button
              type="primary"
              onClick={(e) => changeUserStatus(itemData)}
              danger={enable ? true : false}
            >
              {enable ? "停用" : "启用"}
            </Button>
            <Button
              onClick={(e) => showUserDetail(itemData)}
              icon={<EditOutlined />}
              style={{ marginLeft: "20px" }}
              type="primary"
            >
              编辑
            </Button>
          </div>
        );
      },
    },
  ];

  const onChange = (e) => {
    setPageIndex(e);
  };

  const getUserList = () => {
    API_getUserList({ PageIndex: pageIndex, pageSize: 10 }).then((res) => {
      setApplyList(res.data);
      setTotal(res.total);
    });
  };

  const changeUserStatus = (itemData) => {
    let reqData = {
      id: itemData.userID,
      enable: !itemData.enable,
    };
    API_editUserStatus(reqData).then((res) => {
      message.success("操作成功");
      getUserList();
    });
  };
  useEffect(e=>{
    API_getRoleList().then(e=>{
      let list =e.data.datas;
      list.map(row=>{
        row.label=row.name;
        row.value=row.id;
      })
      setRoleList(list)
    })
  },[])

  useEffect(
    (e) => {
      getUserList();
    },
    [pageIndex]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = async() => {
    setLoading(true);
    if(userInfo.phone==userInfo.masterPhone){
      message.error('主账号不能与子账号相同')
      setLoading(false)
      return
    }
    
    if(userInfo.masterPhone){
    let master=  await API_getUserList({ PageIndex: 1, pageSize: 1 ,Phone:userInfo.masterPhone});
    if(master.data.length>0){
      userInfo.masterUserId=master.data[0].userID
    }
    }else{
      userInfo.masterUserId=0
    }
    if (userInfo.userID) {
      userInfo.id = userInfo.userID;
      API_editUser(userInfo)
        .then((res) => {
          message.success("修改成功");
          getUserList();
          setIsModalOpen(false);
          setUserInfo({});
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      API_addUser(userInfo)
        .then((res) => {
          message.success("新增成功");
          getUserList();
          setIsModalOpen(false);
          setUserInfo({});
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const changeType = (e) => {
    userInfo.userType = e.target.value;
    setUserInfo({...userInfo});
  };

  const changeInput = (e, key) => {
    userInfo[key] = e.target.value;
    setUserInfo({...userInfo});
  };

  const changeRole = (e, key) => {
    userInfo.roleId = e;
    setUserInfo({...userInfo});
  };

  const showUserDetail = (e) => {
    console.log(e)
    setUserInfo({...e});
  };
  useEffect(() => {
    if (userInfo.userID) {
      setIsModalOpen(true);
    }
  }, [userInfo]);

  useEffect(
    (e) => {
      if (!isModalOpen) {
        setUserInfo({});
      }
    },
    [isModalOpen]
  );
  return (
    <div>
      <div className="flex-start table-top">
        <Button
          onClick={(e) => {
            setIsModalOpen(true);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <Table
        columns={columns}
        pagination={{
          pageSize: pageSize,
          total: total,
          onChange: onChange,
          pageSizeOptions: [],
        }}
        dataSource={applyList}
      />
      <Modal
        open={isModalOpen}
        title="用户信息"
        onOk={handleOk}
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            保存
          </Button>,
        ]}
      >
        <div className="input-modal">
          <Row>
            <Col span={6}>姓名:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.name}
                onChange={(e) => changeInput(e, "name")}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col span={6}>电话:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.phone}
                type="number"
                onChange={(e) => changeInput(e, "phone")}
              ></Input>
            </Col>
          </Row>
          {!userInfo.userID && (
            <Row>
              <Col span={6}>登录密码:</Col>
              <Col span={18}>
                <Input
                  defaultValue={userInfo.password}
                  onChange={(e) => changeInput(e, "password")}
                ></Input>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={6}>用户类型:</Col>
            <Col span={18}>
              <Radio.Group onChange={changeType} value={userInfo.userType}>
                <Radio value={1}>个人</Radio>
                <Radio value={2}>企业</Radio>
                <Radio value={3}>员工</Radio>
                <Radio value={4}>机构</Radio>
                <Radio value={5}>客户</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row>
            <Col span={6}>用户权限:</Col>
            <Col span={18}>
                <Select onChange={changeRole} defaultValue={userInfo.roleId} options={roleList}></Select>
            </Col>
          </Row>
          <Row>
            <Col span={6}>主账号:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.masterPhone}
                onChange={(e) => changeInput(e, "masterPhone")}
              ></Input>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default UserManagement;
