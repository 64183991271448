import "./permission.less";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Table,
  Tree,
  TreeSelect,
} from "antd";
import { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  API_getPermissionTree,
  API_getPermission,
  API_savePermission,
  API_deletePermission,
  API_modifyPermission,
} from "../../service/common";

export default function Permission(props) {
  const [permissionTree, setPermissionTree] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [showEditPermission, setShowEditPermission] = useState(false);
  const [editPrimissionInfo, setEditPrimissionInfo] = useState({});
  const [chaceParentId, setChaceParentId] = useState(null);
  const [permissionForm] = Form.useForm();
  const [permissionTotal, setPermissionTotal] = useState(0);
  const[pageIndex,setPageIndex]=useState(1);
  const getPermissionTree = () => {
    API_getPermission({ParentId:0,pageSize:999}).then((res) => {
      res=res.data;
      initTreeData(res);
      console.log(res)
      setPermissionTree(res);
    });
  };
  useEffect(() => {
    getPermissionTree();
  }, []); //eslint-disable-line

  const initTreeData = (data) => {
    if(!data){return}
    data.forEach((item) => {
      item.key = item.id;
      item.value = item.id;
      item.title = item.name;
    });
  };
  const getPermission = () => {
    setPermissionLoading(true);
    API_getPermission({ ParentId: chaceParentId,pageSize:10,pageIndex:pageIndex }).then((res) => {
      setPermissionTotal(res.total)
      setPermissionList(res.data||[]);
      setPermissionLoading(false);
    });
  };
  useEffect(() => {
    getPermission();
  }, [chaceParentId,pageIndex]); //eslint-disable-line

  const handleSelect = (e, data) => {
    let { node } = data;
    setPageIndex(1)
    setChaceParentId(node.id);
  };
  const columns = [
    {
      title: "菜单名",
      align: "center",

      dataIndex: "name",
    },
    {
      title: "权限code",
      align: "center",
      dataIndex: "code",
    },
    {
      title: "图标",
      align: "center",
      dataIndex: "icon",
    },
    {
      title: "导航地址",
      align: "center",
      dataIndex: "path",
    },
    {
      title: "排序",
      align: "center",
      dataIndex: "order",
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "operation",
      fixed: "right",
      width: 240,
      render: (text, row) => (
        <div className="action_area">
          <Button
            type="primary"
            icon={<FormOutlined />}
            onClick={(_) => handleEdit(text, row)}
          >
            编辑
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={(_) => handleDelete(text, row)}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];
  const handleEdit = (text, row) => {
    row.url = row.path;
    setEditPrimissionInfo(row);
    permissionForm.setFieldsValue(row);
    setShowEditPermission(true);
  };
  const handleDelete = (text, row) => {
    Modal.confirm({
      content: "是否删除此数据?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        API_deletePermission(row.id).then((res) => {
          message.success("删除成功");
          getPermissionTree();
          getPermission();
        });
      },
    });
  };
  const handleAfterClose = () => {
    permissionForm.resetFields();
  };
  const handleEditPermission = async (form) => {
    form.id = editPrimissionInfo.id;
    form.type=form.parentId?3:2;
    if (form.id) {
      await API_modifyPermission(form).then((res) => {
        message.success("编辑成功");
      });
    } else {
      await API_savePermission(form).then((res) => {
        message.success("新增成功");
      });
    }
    getPermissionTree();
    getPermission();
    setShowEditPermission(false);
  };
  return (
    <div className="permission">
      <div className="permission_tree">
        <div className="permission_tree_area">
          <Tree
            showLine
            blockNode
            treeData={permissionTree}
            onSelect={handleSelect}
          />
        </div>
      </div>
      <div className="permission_table">
        <div className="custom_card">
          <div className="custom_row">
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {setEditPrimissionInfo({});setShowEditPermission(true)}}
            >
              新增权限
            </Button>
          </div>
        </div>
        <div className="custom_table">
          <Table
            columns={columns}
            dataSource={permissionList}
            loading={permissionLoading}
            scroll={{
              x: 1000,
              y: "calc(100vh - 350px)",
              scrollToFirstRowOnChange: true,
            }}
            pagination={{
              showTotal: () => `共 ${permissionTotal} 条`,
              pageSize: 10,
              total: permissionTotal,
              onChange(pageIndex, pageSize) {
                setPageIndex(pageIndex)
              },
            }}
            rowKey={(columns) => columns.id}
            bordered
          ></Table>
        </div>
      </div>
      <Modal
        title="编辑权限"
        open={showEditPermission}
        okText="确认"
        cancelText="取消"
        onOk={() => permissionForm.submit()}
        onCancel={() => setShowEditPermission(false)}
        afterClose={handleAfterClose}
      >
        <Form
          form={permissionForm}
          onFinish={handleEditPermission}
          initialValues={{}}
          labelCol={{ span: 4, offset: 0 }}
        >
          <Form.Item
            label="菜单名"
            name="name"
            rules={[
              {
                required: true,
                message: "请输入菜单名!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="权限code" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="图标" name="icon">
            <Input />
          </Form.Item>
          <Form.Item label="导航地址" name="url">
            <Input />
          </Form.Item>
          <Form.Item
            label="排序"
            name="order"
            rules={[
              {
                required: true,
                message: "请输入排序!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="父级节点" name="parentId">
            <TreeSelect
              treeData={permissionTree}
              onChange={(value) =>permissionForm.setFieldsValue({ parentId: value })}
            ></TreeSelect>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
