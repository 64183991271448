import { Table, DatePicker,Form, Select, Input, Button, Modal, Divider, message } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import "./invoiceTemplate.less";
import moment from "moment";
import AntdCell from "../antdCell";
import { Link } from "react-router-dom";
const InvoiceTemplate = (props, ref) => {
  const { Option } = Select;
  const { disabled, invoiceDetail } = props;
  const { Column, ColumnGroup } = Table;
  const { TextArea } = Input;
  const [time, setTime] = useState(moment(new Date()).format("yyyy-MM-DD"));
  const [remarks, setRemark] = useState();
  const [buyer, setBuyer] = useState({});
  const [seller, setSeller] = useState({});
  const [company, setCompany] = useState([]);
  const [showCompany, setShowCompany] = useState(false);


  const chooseCompany = () => {

    setShowCompany(true)
  }
  const chooseOne = (e) => {
    setBuyer(e);
    setShowCompany(false)
  }
  const columns2 = [
    { title: "单位名称", key: "name", type: "text" },
    { title: "地址电话", key: "addressPhone", type: "text" },
    { title: "税务登记号", key: "taxAccount", type: "text" },
    { title: "开户银行及账号", key: "bankAccount", type: "text" }
  ];
  const handleKeys = [
    { title: "开票金额", key: "totalMoney", type: "text" },
    { title: "税率", key: "tax", type: "text" },
    { title: "附件", key: "img", type: "file" },
  ];
  const changeData = (e, key1, key2) => {

    let value = e.target.value;
    if (key1 == "buyer") {
      buyer[key2] = value;
      setBuyer({ ...buyer });
    } else if (key1 == "seller") {
      seller[key2] = value;
      setSeller({ ...seller });
    } else if (key1 == "remarks") {
      setRemark(value)
    }
  };
  const onChange = (e) => {
    setTime(moment(e).format("yyyy-MM-DD"));
  };

 React.useImperativeHandle(ref, (e) => ({
   getInvoiceData() {
     return { time, goodsList: tableData, buyer, seller, remarks };
   },
 }));

  const keyList = [
    { title: "货物名称", key: "name" },
    { title: "规格型号", key: "size" },
    { title: "计量单位", key: "unit" },
    { title: "数量", key: "amount" },
    { title: "单价(含税)", key: "price" },
    { title: "税率%", key: "rate" },
    { title: "税额", key: "rateNum" },
    { title: "含税总额", key: "priceNum" },
  ];

  const rateList=[0,1,3,6,9,13,17]

  const disabledList = ["金额", "税额", "含税总额"];

  const countList = ["amount", "money", "rateNum", "priceNum"];
  const [rate, setRate] = useState(0);

  const columns = keyList.map((data) => {
    return {
      title: data.title,
      dataIndex: data.key,
      key: data.key,
      render: (text, record, index) => {
        return disabled ? (
          data.key == "rate" ? (
            <span>{rate}</span>
          ) : (
            <span>{text}</span>
          )
        ) : data.key == "rate" ? (
          <Select
            value={rate}
            size="large"
            bordered={false}
            onChange={(e) => setRate(e)}
          >
            {rateList.map((data) => (
              <Option value={data}>{data}</Option>
            ))}
          </Select>
        ) : (
          <Input
            value={text}
            disabled={disabledList.indexOf(data.title) != -1}
            onChange={(e) => changeTable(e, data.key, record, index)}
          ></Input>
        );
      },
    };
  });
  const [tableData, setTableData] = useState([]);
  useEffect((e) => {
    if (invoiceDetail && invoiceDetail.goodsList.length > 0) {
      setRate(invoiceDetail.goodsList[0].rate);
      setBuyer(invoiceDetail.buyer||{});
      setSeller(invoiceDetail.seller||{})
      setTableData(invoiceDetail.goodsList);
      setChangeValue(!changeValue);
    } else {
      let row = {};
      let list = [];
      keyList.map((data) => {
        if (data.key == 'rate') {
          row[data.key] = 0;
        } else {
         row[data.key] = ""; 
        } 
      });
      for (let i = 0; i < 5; i++) {
        list.push({ ...row });
      }
      setTableData(list);
    }
  }, []);

  const [changeValue, setChangeValue] = useState(false);
  useEffect(
    (e) => {
      tableData.map((row) => {
        row.rate = rate;
        if (row.amount && row.price) {         
          row.priceNum = row.amount * row.price;
          if (row.rate||row.rate==0) {
            row.rateNum = ((row.priceNum)*(row.rate/100) / (1+row.rate/100)).toFixed(2);
          }
          setTableData([...tableData]);
        }
      });
    },
    [changeValue,rate]
  );

  const changeTable = (e, key, record, index) => {
    if (key == 'rate') {
      tableData[index][key] = e;
    } else {
      tableData[index][key] = e.target.value;  
    } 
    setTableData([...tableData]);
    setChangeValue(!changeValue);
  };
  const addRow = () => {

  };
  const [addShow, setAddShow] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [form] = Form.useForm();
  const [record, setRecord] = useState({});
  const onFinish = (values) => {

  };
  const edit = (item,e) => {
    form.setFieldsValue(item);
    setRecord(item);
    setAddShow(true);
    e.stopPropagation();
  }

  const del = (item,e) => {

  }
  return (
    <div className="invoiceTemplate">
      <div className="invoice-title">增值税发票开票通知单</div>
      <div className="flex-start invoice-date">
        <span>申请日期:</span>{" "}
        <DatePicker
          disabled={disabled}
          defaultValue={moment(invoiceDetail?.time)}
          onChange={onChange}
        />
      </div>
      {!disabled && (
        <Button type="primary" className="row-btn" onClick={addRow}>
          插入行
        </Button>
      )}
      <Table dataSource={[]} bordered={true} className="noBody buyerTable">
        <Column
          title={
            <>
              <div>购货单位</div>
              {!disabled && (
                <Button onClick={chooseCompany} type="primary">
                  选择购货单位
                </Button>
              )}
            </>
          }
          dataIndex="购货单位"
          key="购货单位"
        ></Column>
        <ColumnGroup title="名称">
          <Column
            width="100px"
            title="地址电话"
            dataIndex="firstName"
            key="firstName"
          />
        </ColumnGroup>
        <ColumnGroup title={<Input value={buyer?.name} disabled={true}></Input>}>
          <Column
            title={<Input value={buyer?.addressPhone} disabled={true}></Input>}
            dataIndex="addressPhone"
            key="addressPhone"
          />
        </ColumnGroup>
        <ColumnGroup title="税务登记号">
          <Column
            width="150px"
            title="开户银行及帐号"
            dataIndex="firstName"
            key="firstName"
          />
        </ColumnGroup>
        <ColumnGroup
          title={<Input value={buyer?.taxAccount} disabled={true}></Input>}
        >
          <Column
            title={<Input value={buyer?.bankAccount} disabled={true}></Input>}
            dataIndex="bankAccount"
            key="bankAccount"
          />
        </ColumnGroup>
      </Table>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered={true}
        className="data-table"
        summary={(page) => {
          let list = [];
          page.map((row) => {
            let amount = 0;
            let index = 0;
            for (let key in row) {
              if (countList.indexOf(key) != -1) {
                if (row[key] && !isNaN(row[key] * 1)) {
                  list[index] = (list[index] || 0) + row[key] * 1;
                }
              }
              index++;
            }
          });
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {keyList.map((data, i) => {
                  if (i == 0) {
                    return (
                      <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                    );
                  } else {
                    return (
                      <Table.Summary.Cell index={data.key}>
                        {list[i] ? list[i].toFixed(2) : ""}
                      </Table.Summary.Cell>
                    );
                  }
                })}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      ></Table>
      <Table dataSource={[]} bordered={true} className="noBody seller-table">
        <Column
          title={
            <>
              <div>销货单位</div>
              {!disabled && (
                <Link to="/HomePage/UserInfo">
                  <Button type="primary">编辑销货单位</Button>
                </Link>
              )}
            </>
          }
          dataIndex="销货单位"
          key="销货单位"
        />
        <ColumnGroup title="名称">
          <Column
            width="100px"
            title="地址电话"
            dataIndex="firstName"
            key="firstName"
          />
        </ColumnGroup>
        <ColumnGroup title={seller?.name}>
          <Column
            title={seller?.addressPhone}
            dataIndex="firstName"
            key="firstName"
          />
        </ColumnGroup>
        <ColumnGroup title="税务登记号">
          <Column
            width="150px"
            title="开户银行及帐号"
            dataIndex="firstName"
            key="firstName"
          />
        </ColumnGroup>
        <ColumnGroup title={seller?.taxAccount}>
          <Column
            title={seller?.bankAccount}
            dataIndex="firstName"
            key="firstName"
          />
        </ColumnGroup>
        <Column
          width={180}
          title={
            <TextArea
              style={{
                height: "100%",
                resize: "none",
                minWidth: "200px",
                textAlign: "left",
              }}
              rows={4}
              defaultValue={invoiceDetail?.remarks}
              onChange={(e) => changeData(e, "remarks", "remarks")}
              type="text"
              disabled={disabled}
              className="remark-input"
              placeholder="备注"
            ></TextArea>
          }
        />
      </Table>
      {invoiceDetail?.handleResult?.totalMoney>0 && (
        <>
          <Divider>处置反馈</Divider>
          <div style={{ width: "50%" }}>
            <AntdCell
              columns={handleKeys}
              dataSource={invoiceDetail.handleResult}
            ></AntdCell>
          </div>
        </>
      )}
      <Modal
        visible={showCompany}
        onCancel={(e) => {
          setShowCompany(false);
        }}
        width="70%"
        footer={null}
        title="选择购货单位"
        wrapClassName="invoice-company"
      >
        <Button onClick={(e) => {
          setRecord({});
          form.resetFields();
           setAddShow(true);

         }} type="primary">新增</Button>
        <div className="flex-start" style={{flexWarp:"warp"}}>
          {company.map((data) => (
            <div className="company-item" onClick={(e) => chooseOne(data)}>
              <AntdCell columns={columns2} dataSource={data}></AntdCell>
              <div style={{ marginTop: '10px' }} className="flex">
                <Button size="small" onClick={e=>{edit(data,e)}} type="primary">编辑</Button>
                <Button size="small" onClick={e=>{del(data,e)}} type="primary" danger>删除</Button>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        visible={addShow}
        footer={null}
        onCancel={(e) => {
          setAddShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="60%"
        maskClosable={false}
        destroyOnClose={true}
        title="信息编辑"
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          size="large"
          form={form}
        >
          <Form.Item
            label="单位名称"
            name="name"
            required
            rules={[{ required: true, message: "请输入销货单位" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="地址电话"
            name="addressPhone"
            required
            rules={[{ required: true, message: "请输入地址电话" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="税务登记号"
            name="taxAccount"
            required
            rules={[{ required: true, message: "请输入税务登记号" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="开户银行及账号"
            name="bankAccount"
            required
            rules={[{ required: true, message: "请输入开户银行及账号" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              loading={loadingBtn}
              style={{ width: "150px" }}
              type="primary"
              htmlType="submit"
            >
              申请
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default forwardRef(InvoiceTemplate);
