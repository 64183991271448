import { useEffect, useState } from "react"
import { Menu } from 'antd';

import {
  AppstoreOutlined,
} from '@ant-design/icons';
import {
  Link,
} from "react-router-dom";

const { SubMenu } = Menu;

export default function MenuPage(props) {
  const [menuList] = useState([
    {
      name: "s0",
      title: "系统管理",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "Permission",
          title: "权限管理",
        },
        {
          name: "Role",
          title: "角色管理",
        },{
          name: "messagePush",
          title: "短信通知",
        },
      ],
    },
    {
      name: "s1",
      title: "机构管理",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "OrganApply",
          title: "机构申请",
        },
        {
          name: "OrganList",
          title: "机构列表",
        },
      ],
    },
    {
      name: "s2",
      title: "公司管理",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "CompanyApply",
          title: "公司申请",
        },
      ],
    },
    {
      name: "s21",
      title: "企业入驻",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "SettlementApply",
          title: "入驻申请",
        },
      ],
    },
    {
      name: "s3",
      title: "新闻管理",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "News",
          title: "政策信息",
        }
      ],
    },
    {
      name: "s4",
      title: "数据统计",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "statistics/invoice",
          title: "发票状态统计",
        },
        // {
        //   name: "/statistics/list",
        //   title: "发票数量统计",
        // }
      ],
    },
    {
      name: "s5",
      title: "咨询管理",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "SignUp",
          title: "咨询管理",
        }
      ],
    },{
      name: "s6",
      title: "用户管理",
      icon: <AppstoreOutlined />,
      children: [
        {
          name: "userManagement/userManagement",
          title: "注册用户",
        }
      ],
    },
  ]);
  useEffect(() => {
    console.log('引入菜单栏')
  }, [])
  const handleClick = e => {
    console.log(e)
  }
  return (
    <Menu theme="dark" mode="inline" onClick={handleClick}  >
      {
        menuList.map(data => {
          return (
            <SubMenu key={data.name} title={data.title} icon={data.icon}>
              {data.children.map(item => {
                return (

                  <Menu.Item key={item.name} >
                    <Link to={item.name}>
                      {item.title}
                    </Link>
                  </Menu.Item>
                )
              })}
            </SubMenu>
          )
        })
      }
    </Menu>
  );
};