import './news.less'
import { Table, Input, DatePicker, Button, Modal, Select, Form, message,Tag } from "antd";
import React, { useEffect, useState } from "react";
import locale from 'antd/lib/locale/zh_CN';
import { API_addNews, API_getNews,API_deleteNews,API_editNews } from '../../service/common';
import moment from 'moment';
const News = () => {
  const {Option}=Select;
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSIze] = useState(10);
  const [news, setNews] = useState([]);
  const [total, setTotal] = useState(0);
  const columns = [
    {
      title: "新闻标题",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "新闻类型",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <> {text==1?<Tag color="geekblue" key="地方政策">地方政策</Tag>:<Tag color="green" key="国家政策">国家政策</Tag>}     
        </>
      ),
    },
    {
      title: "新闻链接",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "时间",
      dataIndex: "timeText",
      key: "timeText",
    },
    {
      title: "操作",
      key: "action",
      render: (text, record) => (
        <>
          <Button onClick={e=>deleteNews(record)} type="danger">删除</Button>
          <Button style={{marginLeft:"20px"}} type="primary" onClick={e => showDetail(record)}>编辑</Button>
        </>
      ),
    },
  ];

  const onChange = (e) => {
    setPageIndex(e)
  }


  const deleteNews = (item) => {
    Modal.confirm({
      content: "是否删除该新闻?", okText: "确定", cancelText: "取消", onOk: (e => {
        API_deleteNews(item.id).then(res=>{
          getList();
          message.success("已删除");
          Modal.destroyAll()
        })
      })
    })
  }

  const [newsDetail, setNewsDetail] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type,setType]=useState(0)
  const showDetail = (item) => {
    let data={ ...item };
    data.timeText=moment(data.timeText)
    setNewsDetail(data);
    setIsModalVisible(true)
  }

  const showNew= ()=>{
    setNewsDetail({});
    setIsModalVisible(true)
  }
  const getList = ()=>{
    API_getNews({pageIndex:pageIndex,pageSize:10,type:type}).then(res=>{
      setNews(res.data);
      setTotal(res.total)
    })
  }
  useEffect(e => {
    getList();
  }, [pageIndex,type]);
  const onFinish = (values) => {
    let reqData={...values};
    reqData.timeText=moment(values.timeText).format("YYYY-MM-DD");
    if(newsDetail.id){
      reqData.id=newsDetail.id;
      API_editNews(reqData).then(res=>{
        message.success("添加成功!");
        setIsModalVisible(false);
        getList();
      })
    }else{
      API_addNews(reqData).then(res=>{
        message.success("添加成功!");
        setIsModalVisible(false);
        getList();
      })
    }

     
  }
  return (
    <div className='news-page'>
      <div style={{marginBottom:"20px"}}>
      <Select defaultValue={0}  style={{ width: 120 }} onChange={e=>setType(e)}>
        <Option value={0}>全部</Option>
        <Option value={1}>地方政策</Option>
        <Option value={2}>国家政策</Option>
      </Select>
      <Button style={{ width: "80px",marginLeft:"20px" }} type="primary" onClick={showNew}>
              新增
       </Button>
      </div>
      <Table
        columns={columns}
        pagination={{
          pageSize: pageSize,
          total: total,
          onChange: onChange,
          pageSizeOptions: [],
        }}
        dataSource={news}
      />
      <Modal width="70%" wrapClassName='news-modal' destroyOnClose={true}  title="编辑" visible={isModalVisible} footer={null} onCancel={e => setIsModalVisible(false)}>
        <Form
          name="basic"
          initialValues={newsDetail}
          labelCol={{ span: 8 }}
          onFinish={onFinish}
          autoComplete="off"
          size="large"
        >
          <Form.Item
            label="新闻标题"
            name="title"
            rules={[{ required: true, message: "请输入新闻标题" }]}
          >
            <Input placeholder="请输入新闻标题" />
          </Form.Item>
          <Form.Item label="新闻类型" name='type'>
            <Select defaultValue={1}>
              <Select.Option value={1}>地方政策</Select.Option>
              <Select.Option value={2}>国家政策</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="新闻链接"
            name="link"
            rules={[{ required: true, message: "请输入新闻链接" }]}
          >
            <Input placeholder="请输入新闻链接" />
          </Form.Item>
          <Form.Item label="新闻日期" name="timeText">
            <DatePicker locale={locale} placeholder='请选择新闻日期'  />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{ width: "150px" }} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default News