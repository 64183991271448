
import { Table, Tag, Space,Modal } from 'antd';
import React, { useEffect, useState } from "react";
import { API_organList } from '../../service/common';
import { useQuery } from "react-query";
import AntdCell from "../../components/antdCell"
import './organList.less'
import {
  CompassOutlined
} from '@ant-design/icons';
const OrganList = ()=>{
    const columns = [
        {
          title: '机构名称',
          dataIndex: 'organName',
          key: 'organName'
        },
        {
          title: '法人',
          dataIndex: 'legalPerson',
          key: 'legalPerson',
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: '机构类型',
          key: 'serviceType',
          dataIndex: 'serviceType',
          render: tags => (
            <>
              {tags.map(tag => {
                let color = tag.length>2? 'geekblue' : 'green';
                return (
                  <Tag color={color} key={tag}>
                    {tag}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: '审核状态',
          dataIndex: 'isVerify',
          key: 'isVerify',
          render: (text, record) => (
             <div>
              {text?"已审核":"未审核"}
             </div>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <a onClick={e=>showDetail(record)}>查看详情</a>
            </Space>
          ),
        },
      ];

      const columns2=[
        {title:"机构名称",key:"organName",type:"text"},
        {title:"联系电话",key:"phone",type:"text"},
        {title:"联系人",key:"legalPerson",type:"text"},
        {title:"服务类型",key:"serviceType",type:"text",render: tags => (
          <>
            {tags.map(tag => {
              let color = tag.length>2? 'geekblue' : 'green';
              return (
                <Tag color={color} key={tag}>
                  {tag}
                </Tag>
              );
            })}
          </>
        ),},
        {title:"营业执照",key:"businessLicense",type:"image"},
        {title:"行业证书",key:"specialLicense",type:"image"},
        {title:"机构地址",key:"address",render:e=>{
          return <div className='address-btn' onClick={goAddress}>{e.address}<CompassOutlined /></div>
        }}
      ]
      
      const [pageIndex,setPageIndex]=useState(1);
      const [pageSize,setPageSIze]=useState(10);
      const [applyList,setApplyList]=useState([]);
      const [total,setTotal]=useState(0);
      useEffect(e=>{
        API_organList({pageindex:pageIndex,pagesize:pageSize}).then(res=>{
          setApplyList(res.data)
          setTotal(res.total)
        })
      },[pageIndex]);
      const onChange = (e)=>{
        setPageIndex(e)
      }

      const [organDetail,setOrganDetail]=useState({});
      const [isModalVisible,setIsModalVisible]=useState(false);
      const showDetail = (item)=>{
        setOrganDetail({...item});
        setIsModalVisible(true)
      }

      const goAddress = () =>{
        window.open(`http://api.map.baidu.com/marker?location=${organDetail.address.point[1]},${organDetail.address.point[0]}&title=机构位置&content=${organDetail.organName}&output=html&src=webapp.baidu.openAPIdemo`)
      }


      return (
        <div className='organList-page'>
         <Table columns={columns} pagination={{pageSize:pageSize,total:total,onChange:onChange,pageSizeOptions:[]}} dataSource={applyList} />
         <Modal title="机构详情" visible={isModalVisible} footer={null} onCancel={e=>setIsModalVisible(false)}>
            <AntdCell columns={columns2} dataSource={organDetail}></AntdCell>
          </Modal>
        </div>
      )
      
}
export default OrganList