import { DatePicker, Table,Modal,Radio  } from "antd";
import { useEffect, useState } from "react";
import { API_getInvoiceGetDaily, API_getInvoiceList, API_invoiceTotal } from "../../service/common";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import InvoiceTemplate from '../../components/invoiceTemplate/invoiceTemplate'
const HomePage = () => {


  const [option, setOption] = useState({
    series: [
      {
        name: "发票各状态数量",
        type: "pie",
        data: [],
      },
    ],
  });

  const [option2, setOption2] = useState({
     
      xAxis: {
        data: []
      },
      yAxis: {},
      series: [
        {
          data: [],
          type: 'bar'
        }
      ]
    
  });
  const [showPie, setShowPie] = useState(false);
  const [dates, setDates] = useState();
const [value,setValue]=useState([moment(new Date()-1000*3600*24),moment(new Date())])
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 31;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;
    return !!tooEarly || !!tooLate;
  };

  useEffect((e) => {
    API_getInvoiceGetDaily({accountDescription:"2022",pageIndex:1,pageSize:999})
    setShowPie(false);
    let reqData = {
      begin: moment(value[0]).format('YYYY-MM-DD'),
      end:moment(value[1]).format('YYYY-MM-DD')
    }
    API_invoiceTotal(reqData).then((res) => {
      let list = [], list2 = [], xList = [];
      res.data.map(data => {
        data.index=data.completionDegree.split('/')[0]*1
      })
       res.data.sort(function (a, b) {
        return b.index-a.index
       })

      res.data.map((row) => {

        if (row.completionDegree != "总计") {
          let u = {};
          u.name = row.completionDegree + "进度(数量:" + row.num + ")";
          u.value = row.num;
          u.groupId = row.completionDegree;
          if (row.completionDegree == '1/3') {
            u.itemStyle={color:"rgb(250,200,88)"}
          }else if (row.completionDegree == '2/3') {
            u.itemStyle = { color: "rgb(84,112,198)" }
          }else if (row.completionDegree == '3/3') { 
            u.itemStyle={color:"rgb(145,206,117)"} 
          }
          list.push(u);
          list2.push(u.name)
          xList.push(u)
        }

      });
      let json = { ...option };
      let json2 = { ...option2 };
      json.series[0].data = list;
      json2.xAxis.data = list2;
      json2.series[0].data = xList;
      setOption({ ...json });
      setOption2({ ...json2 });
      setShowPie(true);
    });
  }, [value]);




  const [showList,setShowList]=useState(false)
  const [pageIndex,setPageIndex]=useState(1);
  const [pageSize,setPageSIze]=useState(10);
  const [applyList,setApplyList]=useState([]);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(0);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const openInvoiceDetail = (e) => {
    let reqData = e.invoiceNotice;
    reqData.handleResult = {
      img: e.handleResult,
      tax: e.tax,
      totalMoney: e.totalMoney,
    };
    setInvoiceData(reqData);
    setInvoiceShow(true);
  };
  const onclick = {
    'click': (e) => {
      let type = e.data.groupId;
      if (type == '1/3') {
        setType(1)
      }else if (type == '2/3') {
        setType(2)
      } else if (type == '3/3') {
        setType(5)
      } 
      setApplyList([])
      setShowList(true)
    }
  }
  const columns = [
    { title: "发票抬头", key: "header", dataIndex: "header" },
  ,
    {
      title: "发票详情",
      key: "header",
      render: (text, record) => {
        return (
          <div
            onClick={(e) => {
              openInvoiceDetail(record);
            }}
            className="check-invoice"
          >
            点击查看
          </div>
        );
      },
    },
    { title: "发票类型", key: "type", dataIndex: "type" },
    { title: "开票金额", key: "money", dataIndex: "money" },
    { title: "纳税人类型", key: "category", dataIndex: "category" },
    { title: "申请时间", key: "createTime", dataIndex: "createTime" },
    {
      title: "发票状态", key: "orderState", dataIndex: "orderState",
      render: (text) => {
        if (text == 1) {
        return '待受理'
        } else if (text == 2) {
          return "带处置"
        } else if (text == 3) {
          return "已处置"
        } else {
          return "其他"
      }
  }  },
  ]

  const onChange = (e)=>{
    setPageIndex(e)
  }

  useEffect(e => {
    if (showList) {
      let reqData = {
        begin: moment(value[0]).format('YYYY-MM-DD'),
        end: moment(value[1]).format('YYYY-MM-DD'),
        accept: type,
        pageindex: pageIndex,
        pagesize: pageSize
      }
      API_getInvoiceList(reqData).then(res => {
        setApplyList(res.data)
      })
    }
    
  }, [showList, pageIndex])
  
  const [countType, setCountType] = useState(1);
  const[selectType,setSelectType]=useState("month")
  const changeType = (e) => {
    setCountType(e.target.value)
    switch (e.target.value) {
      case 1:
        return setSelectType("month");
      case 2:
        return setSelectType("year");
      case 3:
        return setSelectType('quarter')
    }
  }

  return (
    <div className="invoiceCount-page">
      <div className="label">
      <Radio.Group onChange={changeType} value={countType}>
          <Radio value={1}>按日统计</Radio>
          <Radio value={2}>按月统计</Radio>
          <Radio value={3}>按季度统计</Radio>
      </Radio.Group>
        <DatePicker

          onChange={(val) => setValue(val)}
          picker={selectType}
        />
      </div>
      <div className="chart">
        <div className="chart-title">发票完成情况统计图</div>
        {showPie && (
          <ReactEcharts onEvents={onclick} notMerge={true} option={option2}></ReactEcharts>
        )}
      </div>
      <Modal visible={showList} width='70%' title="发票列表" footer={null}  onCancel={(e) => {
        setShowList(false);
        setPageIndex(1)
      }}>
        <Table columns={columns} pagination={{pageSize:pageSize,total:total,onChange:onChange,pageSizeOptions:[]}} dataSource={applyList} />
      </Modal>
      <Modal
        visible={invoiceShow}
        footer={null}
        title="发票详情"
        onCancel={(e) => {
          setInvoiceShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="80%"
        maskClosable={false}
        destroyOnClose={true}
      >
        <InvoiceTemplate
          invoiceDetail={invoiceData}
          disabled={true}
        ></InvoiceTemplate>
      </Modal>
    </div>
  );
};

export default HomePage;
