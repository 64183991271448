import './login.less'

import React from 'react'
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { API_getUserInfo, API_accountLogin } from '../service/common'

export default LoginPage
function LoginPage(props) {
  const onFinish = values => {
    API_accountLogin(values).then(res => {
      if (res.status != 'OK') {
        // message.warning('帐号或密码不正确');
        // return;
      } else {
        props.navigate('/')
        localStorage.setItem("accessToken", res.data);        
      }
    })

  }
  return (
    <div className="login">
      <img className='login_bg' src={require('./../assets/imgs/loginbg.jpg')} alt='' />
      <section className='login_content'>
        <div className='login_title'>用户登录</div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="phone"
            rules={[{ required: true, message: '请输入账号' }]}
          >
            <Input maxLength={11} prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="请输入账号" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码!' }, { min: 6, message: '密码长度小于6位' },]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              size="large"
              placeholder="请输入密码"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" shape="round" size='large' htmlType="submit" className="login-form-button" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </section>
    </div>
  );
};

