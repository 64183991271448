
import { Result, Button } from "antd";
export default (props) => {
  const returnPage = () => {
    props.navigate(-1)
  }
  return (
    <Result
      status="404"
      title="如果没有404，网络世界还有什么意义"
      extra={<Button type="primary" shape="round" onClick={returnPage}>返回上一页</Button>}
    />
  )
};
