
import { Table, Tag, Space,Button,Modal,message } from 'antd';
import React, { useEffect, useState } from "react";
import { API_companyApplyList,API_companyVerify } from '../../service/common';
import { useQuery } from "react-query";
import AntdCell from "../../components/antdCell"
import {
  CompassOutlined
} from '@ant-design/icons';
const CompanyPage = ()=>{
    const columns = [
        {
          title: '公司名字',
          dataIndex: 'firstName',
          key: 'firstName'
        },
        {
          title: '备选名字',
          dataIndex: 'backName',
          key: 'backName'
        },
        {
          title: '注册资金',
          dataIndex: 'money',
          key: 'money'
        },
        {
          title: '法人',
          dataIndex: 'legalPerson',
          key: 'legalPerson',
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: '审核状态',
          dataIndex: 'isVerify',
          key: 'isVerify',
          render: (text, record) => (
             <div>
              {text?"已审核":"未审核"}
             </div>
          ),
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <>
             {record.isVerify?(
               <Button type='ghost' disabled>已审核</Button>
             ):(
               <Button type='primary' onClick={e=>showDetail(record)}>审核</Button>
             )}
            </>
          ),
        },
      ];

      const columns2=[
        {title:"公司名字",key:"firstName",type:"text"},
        {title:"联系电话",key:"phone",type:"text"},
        {title:"联系人",key:"legalPerson",type:"text"},
        {title:"注册资金",key:"money",type:"text"},
        {title:"公司地址",key:"defatluAddress",render:e=>{
          return <div className='address-btn' onClick={goAddress}>{e?.address}<CompassOutlined /></div>
        }}
      ]
      
      const [pageIndex,setPageIndex]=useState(1);
      const [pageSize,setPageSIze]=useState(10);
      const [applyList,setApplyList]=useState([]);
      const [total,setTotal]=useState(0);
      useEffect(e=>{
        API_companyApplyList({pageindex:pageIndex,pagesize:pageSize,onlyunaccept:false,companyid:0}).then(res=>{
          setApplyList(res.data)
          setTotal(res.total)
        })
      },[pageIndex]);
      const onChange = (e)=>{
        setPageIndex(e)
      }

      const goAddress = () =>{
        window.open(`http://api.map.baidu.com/marker?location=${companyDetail.defatluAddress.point[1]},${companyDetail.defatluAddress.point[0]}&title=机构位置&content=${companyDetail.organName}&output=html&src=webapp.baidu.openAPIdemo`)
      }

      const verifyCompany= (item)=>{

        Modal.confirm({content:"是否审核通过该公司?",okText:"确定",cancelText:"取消",onOk:(e=>{
          API_companyVerify({orderId:companyDetail.id}).then(res=>{
            message.success("审核成功!");
            item.isVerify=true;
            return Promise.resolve();
          })
        })})
      }

      const [companyDetail,setCompanyDetail]=useState({});
      const [isModalVisible,setIsModalVisible]=useState(false);
      const showDetail = (item)=>{
        setCompanyDetail({...item});
        setIsModalVisible(true)
      }


      return (
        <div className='company-apply'>
          <Table columns={columns} pagination={{pageSize:pageSize,total:total,onChange:onChange,pageSizeOptions:[]}} dataSource={applyList} />
          <Modal title="机构详情" visible={isModalVisible} okText="审核通过" onOk={verifyCompany} onCancel={e=>setIsModalVisible(false)}>
            <AntdCell columns={columns2} dataSource={companyDetail}></AntdCell>
         </Modal>
        </div>
      )
      
}
export default CompanyPage