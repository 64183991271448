import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table
} from "antd";
import { useEffect, useState } from "react";
import {
  API_addNoticeUser,
  API_editNoticeUser,
  API_deleteNoticeUser,
  API_getRoleList,
  API_getNoticeUser,
} from "../../service/common";
import { EditOutlined } from "@ant-design/icons";
const MessagePush = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSIze] = useState(10);
  const [applyList, setApplyList] = useState([]);
  const [total, setTotal] = useState(0);
  const columns = [
    { title: "姓名", align: "center", key: "name", dataIndex: "name" },
    {
      title: "手机号",
      key: "tel",
      dataIndex: "tel",
      align: "center",
    },
    {
      title: "操作",
      key: "enable",
      dataIndex: "enable",
      align: "center",
      render: function (enable, itemData) {
        return (
          <div>
            <Button
              type="primary"
              onClick={(e) => changeUserStatus(itemData)}
              danger
            >
             删除
            </Button>
            <Button
              onClick={(e) => showUserDetail(itemData)}
              icon={<EditOutlined />}
              style={{ marginLeft: "20px" }}
              type="primary"
            >
              编辑
            </Button>
          </div>
        );
      },
    },
  ];

  const onChange = (e) => {
    setPageIndex(e);
  };

  const getUserList = () => {
    API_getNoticeUser({ PageIndex: pageIndex, pageSize: 10 }).then((res) => {
      setApplyList(res.data);
      setTotal(res.total);
    });
  };

  const changeUserStatus = (itemData) => {
    let reqData = {
      id: itemData.userID,
      enable: !itemData.enable,
    };
    API_deleteNoticeUser(reqData).then((res) => {
      message.success("操作成功");
      getUserList();
    });
  };

  const showUserDetail =(e)=>{

  }


  useEffect(
    (e) => {
      getUserList();
    },
    [pageIndex]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = async() => {
    setLoading(true);
    userInfo.eventType=1;
    if (userInfo.id) {
      API_editNoticeUser(userInfo)
        .then((res) => {
          message.success("修改成功");
          getUserList();
          setIsModalOpen(false);
          setUserInfo({});
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      API_addNoticeUser(userInfo)
        .then((res) => {
          message.success("新增成功");
          getUserList();
          setIsModalOpen(false);
          setUserInfo({});
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };



  const changeInput = (e, key) => {
    userInfo[key] = e.target.value;
    setUserInfo({...userInfo});
  };

  return (
    <div>
      <div className="flex-start table-top">
        <Button
          onClick={(e) => {
            setIsModalOpen(true);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <Table
        columns={columns}
        pagination={{
          pageSize: pageSize,
          total: total,
          onChange: onChange,
          pageSizeOptions: [],
        }}
        dataSource={applyList}
      />
      <Modal
        open={isModalOpen}
        title="用户信息"
        onOk={handleOk}
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            保存
          </Button>,
        ]}
      >
        <div className="input-modal">
          <Row>
            <Col span={6}>姓名:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.name}
                onChange={(e) => changeInput(e, "name")}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col span={6}>电话:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.tel}
                type="number"
                onChange={(e) => changeInput(e, "tel")}
              ></Input>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default MessagePush;
  