import { Button, Modal, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { API_getSettlementApply, API_getSettlementAudit } from "../../service/common";

const SettlementApply =(props)=> {
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSIze] = useState(10);
    const [applyList, setApplyList] = useState([]);
    const [total, setTotal] = useState(0);


    useEffect(e=>{
        getList()
    },[pageIndex,pageSize])
    const getList = ()=>{
        let reqData={
            PageIndex:pageIndex,
            PageSize:pageSize
        }
        API_getSettlementApply(reqData).then(res=>{
            setApplyList(res.data)
        })
    }

    const audit = (e,audit)  =>{
        Modal.confirm({
          content: `是否${audit?'同意':'拒绝'}该申请?`,
          cancelText: "取消",
          okText: "确认",
          centered: true,
          onOk: () => {
            let reqData={
                id:e.id,
                audit:audit
            }
           API_getSettlementAudit(reqData).then(res=>{
               getList()
           })
          },
        });
        
    }

    const columns = [
      { title: "申请企业", align: "center", key: "enterprise", dataIndex: "enterprise" },
      {
        title: "经营范围",
        key: "businessScope",
        dataIndex: "businessScope",
        align: "center",
      },
      {
        title: "需求面积",
        key: "area",
        dataIndex: "area",
        align: "center",
      },
      {
        title: "企业人数",
        key: "peoples",
        align: "center",
        dataIndex: "peoples",
      },
      {
        title: "预估产值",
        key: "outputValue",
        dataIndex: "outputValue",
        align: "center",
      },
      {
        title: "预估缴税",
        key: "tax",
        dataIndex: "tax",
        align: "center",
      },
      {
        title: "联系方式",
        key: "contact",
        dataIndex: "contact",
        align: "center",
        render: function (contact) {
          return `${contact.name}-${contact.tel}`
        },
      },
      {
        title: "操作",
        key: "auditStatus",
        dataIndex: "auditStatus",
        align: "center",
        render: function (auditStatus, itemData) {
          return (
            <>
            {auditStatus==null&&<div>
              <Button
                type="primary"
                danger
                onClick={(e) => audit(itemData, false)}
              >
                驳回
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                type="primary"
                onClick={(e) => audit(itemData, true)}
              >
                同意
              </Button>
            </div>}
            {auditStatus==true&&<Tag color="#2db7f5">已同意</Tag>}
            {auditStatus==false&&<Tag color="#f50">已驳回</Tag>}
            </>
          );
        },
      },
    ];

    return (
      <>
         <Table
           columns={columns}
           pagination={{
             pageSize: pageSize,
             total: total,
             onChange: e=>{setPageIndex(e)},
             pageSizeOptions: [],
           }}
           dataSource={applyList}
         />
      </>
    )
}

export default SettlementApply
