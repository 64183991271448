import {
    Button,
    Table,
    TreeSelect,
    Modal,
    Input,
    Form,
    message,
} from "antd";

import { useEffect, useState } from "react";
import {
    API_getPermissionTree,
    API_getRoleList,
    API_saveRole,
    API_deleteRole,
    API_modifyRole,
    API_getRoleLimits,
} from "../../service/common";
import {
    FormOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined,
} from "@ant-design/icons";
const { SHOW_ALL } = TreeSelect;

export default function Role(props) {
    const [permissionTree, setPermissionTree] = useState([]);
    const [initSearchAfter, setInitSearchAfter] = useState(1);
    const [reqRoleInfo, setReqRoleInfo] = useState({
        Name: null,
        PageIndex: 1,
        PageSize: 10,
    });
    const [roleList, setRoleList] = useState([]);
    const [roleTotal, setRoleTotal] = useState(0);
    const [roleLoading, setRoleLoading] = useState(false);
    const [showEditRole, setShowEditRole] = useState(false);
    const [limits, setLimits] = useState([])
    const [roleId, setRoleId] = useState(null)
    const [roleForm] = Form.useForm()
    const getRoleList = () => {
        setRoleLoading(true);
        API_getRoleList(reqRoleInfo).then((res) => {
            res=res.data;
            setRoleList(res.datas);
            setRoleTotal(res.totalCount);
            setRoleLoading(false);
        });
    };
    useEffect(() => {
        API_getPermissionTree().then(res => {
            res=res.data;
            initTreeData(res, null)
            setPermissionTree(res);
        })
    }, [])  //eslint-disable-line
    const initTreeData = (data, index) => {
        data.forEach((item, index2) => {
            item.key = item.id;
            item.value = item.id;
            item.title = item.name;
            if (item.children.length) {
                initTreeData(item.children, index2);
            }
        });
    };
    useEffect(() => {
        getRoleList();
    }, [reqRoleInfo.PageIndex, reqRoleInfo.PageSize]); //eslint-disable-line
    const columns = [
        {
            title: "角色名",
            align: "center",
            width: 300,
            dataIndex: "name",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },

        {
            title: "操作",
            align: "center",
            width: 240,
            dataIndex: "operation",
            render: (text, row) => (
                <div className="action_area">
                    <Button
                        type="primary"
                        icon={<FormOutlined />}
                        onClick={(_) => handleEdit(text, row)}
                    >
                        编辑
                    </Button>
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={(_) => handleDelete(text, row)}
                    >
                        删除
                    </Button>
                </div>
            ),
        },
    ];
    const tProps = {
        treeData: permissionTree,
        value: limits,
        treeCheckable: true,
        showCheckedStrategy: SHOW_ALL,
        placeholder: '请选择角色权限',
        treeLine: true,
        style: {
            width: '100%',
        },
    }
    const handleEdit = (text, row) => {
        console.log(row)
        API_getRoleLimits(row.id).then(res => {
            res=res.data;
            let initLimits = []
            res.forEach(data => {
                initLimits.push(data.id)
            })
            setLimits(initLimits)
        })
        setRoleId(row.id)
        roleForm.setFieldsValue(row)
        setShowEditRole(true)
    };
    const handleDelete = (text, row) => {
        Modal.confirm({
            content: '是否删除此数据?',
            cancelText: '取消',
            okText: '确认',
            centered: true,
            onOk: () => {
                API_deleteRole(row.id).then(res => {
                    message.success('删除成功')
                    getRoleList()
                })
            }
        })
    };
    const handleEditRole = async (form) => {
        if (roleId) {
            form.id = roleId
            form.limits = limits
            await API_modifyRole(form).then(res => {
                message.success('编辑完成')

            })
        } else {
            form.limits = limits
            await API_saveRole(form).then(res => {
                message.success('新增完成')
            })
        }
        getRoleList();
        setShowEditRole(false)
    }
    const handleAfterClose = () => {
        roleForm.resetFields()
        setLimits([])
        setRoleId(null)
    }
    return (
        <>
            <div className="custom_card">
                <div className="custom_row">
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => setShowEditRole(true)}
                    >
                        新增角色
                    </Button>
                    <div className="custom_cell">|</div>
                    角色名
                    <Input
                        className="custom_input"
                        name={reqRoleInfo.Name}
                        placeholder="请输入角色名"
                        onChange={(e) => setReqRoleInfo({ ...reqRoleInfo, Name: e.target.value })}
                    ></Input>
                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={() => {
                            if (reqRoleInfo.PageIndex > 1) {
                                setInitSearchAfter(1);
                                setReqRoleInfo({
                                    ...reqRoleInfo,
                                    PageIndex: 1,
                                });
                                return;
                            }
                            getRoleList();
                        }}
                    >
                        搜索
                    </Button>
                </div>
            </div>
            <div className="custom_table self_area">
                <Table
                    columns={columns}
                    dataSource={roleList}
                    rowKey={(columns) => columns.id}
                    pagination={{
                        current: initSearchAfter,
                        total: roleTotal,
                        showTotal: () => {
                            return `共 ${roleTotal} 条`;
                        },
                        pageSizeOptions: [10, 20, 50, 100],
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange(e) {
                            setInitSearchAfter(e);
                            reqRoleInfo.PageIndex = e;
                            setReqRoleInfo({ ...reqRoleInfo });
                        },
                        onShowSizeChange(e, size) {
                            reqRoleInfo.PageSize = size;
                            setReqRoleInfo({ ...reqRoleInfo });
                        },
                    }}
                    loading={roleLoading}
                    scroll={{ y: `calc(100vh - 316px)`, scrollToFirstRowOnChange: true }}
                    bordered
                />
            </div>
            <Modal
                title="编辑角色"
                open={showEditRole}
                okText="确认"
                cancelText="取消"
                onOk={() => roleForm.submit()}
                onCancel={() => setShowEditRole(false)}
                afterClose={handleAfterClose}
            >
                <Form
                    form={roleForm}
                    onFinish={handleEditRole}
                    initialValues={{}}
                    labelCol={{ span: 4, offset: 0 }}
                >
                    <Form.Item
                        label="角色名"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "请输入角色名!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="角色类型"
                        name="roleType"
                        rules={[
                            {
                                required: true,
                                message: "请输入角色类型!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="角色说明" rules={[
                        {
                            required: true,
                            message: "请输入角色说明!",
                        },
                    ]}  name="remark">
                        <Input />
                    </Form.Item>
                    <Form.Item label="角色权限">
                        <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
                            <TreeSelect {...tProps} onChange={(values) => setLimits(values)} />
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
