import React, { useEffect, useState } from "react";
import './antdCell.less'
import { Image } from 'antd';
const AntdCell = (props) => {
    const {dataSource,columns}=props;
    return (
        <div className='flex-content antdCell-content'>
            {columns.map(data => {
                if(data.render){
                    return (
                        <div className='flex' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">{data.render(dataSource[data.key])}</div>
                        </div>
                    )
                }else if(data.type=="text"){
                    return (
                        <div className='flex' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">{dataSource[data.key]}</div>
                        </div>
                    )
                }else if(data.type=='image'){
                    return (
                        <div className='flex' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">
                                <Image width={100} src={dataSource[data.key]}></Image>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default AntdCell