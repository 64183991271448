import http from './http'

//登陆
export const API_accountLogin = (data) => http.post("/UserLogin/UserLogin", data);

//获取用户信息

export const API_getUserInfo = () => http.get('/User/GetUserInfo')

//获取机构申请列表

export const API_organApplyList = (data)=> http.get('Organ/GetWaitValidOrgans',data)

//获取公司列表

export const API_companyApplyList= (data)=> http.get('CompanyRegistration/GetOrgans',data)

//获取机构列表

export const API_organList = (data)=> http.get('Organ/GetOrgans',data)


//审核

export const API_organVerify = (data)=> http.post('Organ/OrganVerify',data)


//审核

export const API_companyVerify = (data)=> http.post('CompanyRegistration/Acceptance',data)


//添加新闻
export const API_addNews= (data)=> http.post('NationalPolicy/Add',data)

//更新新闻
export const API_editNews= (data)=> http.post('NationalPolicy/Update',data)

//删除新闻
export const API_deleteNews= (data)=> http.post('NationalPolicy/Delete',data)


//获取新闻列表

export const API_getNews = (data) => http.get('NationalPolicy/Gets', data)


//获取发票统计

export const API_invoiceTotal = (data) => http.post('ManagementOfInvoices/GetSumByTime', data)


//获取发票列表


export const API_getInvoiceList = (data) => http.get('ManagementOfInvoices/GetAllApplys', data)



//获取每月总数


export const API_getInvoiceGetDaily = (data) => http.get('ManagementOfInvoices/GetDaily', data)


//获取报名列表


export const API_getSignUpList = (data) => http.get("ServiceSign/GetSignUps", data)


//获取电话

export const API_getSignUpPhone = (data) => http.get("ServiceSign/GetPhone", data)


//设置电话

export const API_setSignUpPhone = (data) => http.post("ServiceSign/SetPhone", data)


//获取用户列表
export const API_getUserList = (data) => http.get("UserManage", data)

//添加用户
export const API_addUser = (data) => http.post("UserManage", data)

//修改用户
export const API_editUser = (data) => http.put("UserManage", data)

//修改用户状态
export const API_editUserStatus = (data) => http.put("UserManage/enable", data)


//获取权限树

export const API_getPermissionTree = () => http.get("Permission/GetTree")

//获取权限
export const API_getPermission = (data) => http.get("Permission", data)

//保存权限
export const API_savePermission = (data) => http.post("Permission", data)

//删除权限
export const API_deletePermission = (data) => http.delete("Permission", data)

//编辑权限
export const API_modifyPermission = (data) => http.put("Permission", data)

//获取角色列表
export const API_getRoleList = (data) => http.get("Role", data)

//保存角色
export const API_saveRole = (data) => http.post("Role", data)

//删除角色
export const API_deleteRole = (data) => http.delete("Role/"+data, {})

//编辑角色
export const API_modifyRole = (data) => http.put("Role", data)

//获取角色权限
export const API_getRoleLimits = (data) => http.get("Role/GetLimits/"+data, {})

//获取入驻申请记录
export const API_getSettlementApply = (data) => http.get("park/enterprisesettleapply", data)

//审批
export const API_getSettlementAudit = (data) => http.put("park/enterprisesettleapply", data)

//获取通知用户
export const API_getNoticeUser = (data) => http.get("eventnotifier", data)

//编辑通知用户
export const API_editNoticeUser = (data) => http.put("eventnotifier", data)

//删除通知用户
export const API_deleteNoticeUser = (data) => http.delete("eventnotifier", data)

//添加通知用户
export const API_addNoticeUser = (data) => http.post("eventnotifier", data)

