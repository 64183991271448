import './App.less';
import IndexPage from './pages/indexPage'
import Login from './pages/login'
import Page404 from "./pages/404";
import OrganApply from './pages/apply/organ';
import OrganList from './pages/organ/organList';
import CompanyApply from './pages/apply/company';
import Permission from './pages/system/permission';
import News from './pages/news/news';
import InvoiceCount from './pages/statistics/invoice'
import InvoiceList from './pages/statistics/list'
import {
  HashRouter as Router,
  useRoutes,
  useNavigate,
  useParams,
} from "react-router-dom";
import moment from "moment";
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import SignUp from './pages/signUp/signUp';
import UserManagement from './pages/userManagement/userManagement';
import Role from './pages/system/role';
import SettlementApply from './pages/settlement/settlementApply';
import MessagePush from './pages/messagePush/messagePush';
moment.locale('zh-cn');
const RouteList = () => {
  const navigate = useNavigate();
  let Routes = useRoutes([
    {
      path: "/login",
      element: <Login useParams={useParams} navigate={navigate}></Login>,
    },
    {
      path: "/",
      element: <IndexPage navigate={navigate}></IndexPage>,
      children: [
        {
          path: "OrganApply",
          element: <OrganApply navigate={navigate} />,
        },
        {
          path: "CompanyApply",
          element: <CompanyApply navigate={navigate} />,
        },
        {
          path: "OrganList",
          element: <OrganList navigate={navigate}></OrganList>,
        },
        {
          path: "News",
          element: <News navigate={navigate}></News>,
        },
        {
          path: "statistics/invoice",
          element: <InvoiceCount navigate={navigate}></InvoiceCount>,
        },
        {
          path: "statistics/list",
          element: <InvoiceList navigate={navigate}></InvoiceList>,
        },
        {
          path: "SignUp",
          element: <SignUp navigate={navigate}></SignUp>,
        },
        {
          path: "userManagement/userManagement",
          element: <UserManagement navigate={navigate}></UserManagement>,
        },
        {
          path: "Permission",
          element: <Permission navigate={navigate}></Permission>,
        },
        {
          path: "Role",
          element: <Role navigate={navigate}></Role>,
        },
        {
          path: "settlementApply",
          element: <SettlementApply navigate={navigate}></SettlementApply>,
        },
        {
          path: "messagePush",
          element: <MessagePush navigate={navigate}></MessagePush>,
        },
      ],
    },
    {
      path: "*",
      element: <Page404 navigate={navigate}></Page404>,
    },
  ]);
  return Routes
}
function App() {
  return (
    <ConfigProvider locale={zhCN}>
    <Router>
      <RouteList />
      </Router>
      </ConfigProvider>
  );
}

export default App;
