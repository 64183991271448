
import { Table, Tag, Space,Button,Modal,message } from 'antd';
import React, { useEffect, useState } from "react";
import { API_organApplyList,API_organVerify } from '../../service/common';
import { useQuery } from "react-query";
const OrganPage = ()=>{
    const columns = [
        {
          title: '机构名称',
          dataIndex: 'organName',
          key: 'organName'
        },
        {
          title: '法人',
          dataIndex: 'legalPerson',
          key: 'legalPerson',
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: '机构类型',
          key: 'serviceType',
          dataIndex: 'serviceType',
          render: tags => (
            <>
              {tags.map(tag => {
                let color = tag.length>2? 'geekblue' : 'green';
                return (
                  <Tag color={color} key={tag}>
                    {tag}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: '审核状态',
          dataIndex: 'isVerify',
          key: 'isVerify',
          render: (text, record) => (
             <div>
              {text?"已审核":"未审核"}
             </div>
          ),
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <>
             {record.isVerify?(
               <Button type='ghost' disabled>已审核</Button>
             ):(
               <Button type='primary' onClick={e=>verifyOrgan(record)}>审核通过</Button>
             )}
            </>
          ),
        },
      ];
      
      const [pageIndex,setPageIndex]=useState(1);
      const [pageSize,setPageSIze]=useState(10);
      const [applyList,setApplyList]=useState([]);
      const [total,setTotal]=useState(0);
      useEffect(e=>{
        API_organApplyList({pageindex:pageIndex,pagesize:pageSize}).then(res=>{
          setApplyList(res.data)
          setTotal(res.total)
        })
      },[pageIndex]);
      const onChange = (e)=>{
        setPageIndex(e)
      }

      const verifyOrgan= (item)=>{
        console.log(item)
        Modal.confirm({content:"是否审核通过该机构?",okText:"确定",cancelText:"取消",onOk:(e=>{
          API_organVerify({ids:[item.organid]}).then(res=>{
            message.success("审核成功!");
            item.isVerify=true;
            Modal.destroyAll();
          })
        })})
      }


      return (
        <Table columns={columns} pagination={{pageSize:pageSize,total:total,onChange:onChange,pageSizeOptions:[]}} dataSource={applyList} />
      )
      
}
export default OrganPage